import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "react-slick"
import { BsX } from "react-icons/bs"

export default class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Fortis_Aircon: true,
      Fortis_RealEstate: true,
      Fortis_Electrical: true,
      Fortis_Plumbing: true,
      Fortis_Reticuilation: true,

      filter: [
        "Fortis_Aircon",
        "Fortis_RealEstate",
        "Fortis_Electrical",
        "Fortis-Plumbing",
        "Fortis-Reticuilation",
      ],
      hideFilters: true,
      popUp: false,
    }
    // link the images to the slider
    this.sliderRef = React.createRef()
  }

  handleCheck = event => {
    const checkboxName = event.target.name
    let filtered = this.state.filter
    if (this.state[checkboxName]) {
      filtered.splice(filtered.indexOf(checkboxName), 1)
    } else {
      filtered.push(checkboxName)
    }
    this.setState({
      [checkboxName]: !this.state[event.target.name],
      filter: [...filtered],
    })
  }

  handleBtn = () => {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  handleSlider = index => {
    this.sliderRef.current.slickGoTo(index)
    this.setState({ popUp: true })
  }
  handleClose = () => {
    this.setState({ popUp: false })
  }

  render() {
    let gallery = this.props.data.wpPage.gallery.galleryImage
    let filtered = gallery.filter(image => {
      if (image.categories !== null)
        return image.categories.nodes.some(node =>
          this.state.filter.includes(node.name.split(" ").join("_"))
        )
      return
    })

    //slider
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    }

    return (
      <Layout>
        <SEO title="Gallery" />
        <section className="gallery">
          <div className="container">
            <div className="row row-first">
              <h1>Gallery</h1>
              <a className="btn" onClick={this.handleBtn}>
                {this.state.hideFilters ? "show filters" : "hide filters"}
              </a>
            </div>
            <div
              className={`row categories ${
                this.state.hideFilters ? "hideFilters" : ""
              }`}
            >
              <h3>Categories:</h3>

              {/* Check boxes */}
              <div className="filter-checkbox">
                Fortis Aircon
                <input
                  type="checkbox"
                  name="Fortis_Aircon"
                  onChange={this.handleCheck}
                  checked={this.state.Fortis_Aircon}
                />
              </div>
              <div className="filter-checkbox">
                Fortis Real Estate
                <input
                  type="checkbox"
                  name="Fortis_RealEstate"
                  onChange={this.handleCheck}
                  checked={this.state.Fortis_RealEstate}
                />
              </div>
              <div className="filter-checkbox">
                Fortis Electrical
                <input
                  type="checkbox"
                  name="Fortis_Electrical"
                  onChange={this.handleCheck}
                  checked={this.state.Fortis_Electrical}
                />
              </div>
              <div className="filter-checkbox">
                Fortis Plumbing
                <input
                  type="checkbox"
                  name="Fortis_Plumbing"
                  onChange={this.handleCheck}
                  checked={this.state.Fortis_Plumbing}
                />
              </div>
              <div className="filter-checkbox">
                Fortis Reticuilation
                <input
                  type="checkbox"
                  name="Fortis_Reticuilation"
                  onChange={this.handleCheck}
                  checked={this.state.Fortis_Reticuilation}
                />
              </div>
            </div>
            <div className="row row-gallery">
              {filtered.map((image, index) => (
                <div
                  onClick={() => this.handleSlider(index)}
                  className="col-3"
                  key={index}
                >
                  <div className="img-container">
                    <img src={image.sourceUrl} alt={image.title} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section
          className={`fullSlider${this.state.popUp ? " activeSlider" : ""}`}
        >
          <div className="closeButton" onClick={this.handleClose}>
            <BsX />
          </div>
          <Slider ref={this.sliderRef} {...settings}>
            {filtered.map((image, index) => (
              <div key="index">
                <img src={image.sourceUrl} alt={image.title} />
              </div>
            ))}
          </Slider>
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query GalleryQuery {
    wpPage(id: { eq: "cG9zdDo3NTY=" }) {
      gallery {
        galleryImage {
          sourceUrl
          title
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`
